.not-found {
    min-height: 100vh;
    background-color: #000000;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .content {
    text-align: center;
    position: relative;
    z-index: 1;
  }
  
  .not-found h1 {
    font-size: 8rem;
    margin: 0;
    background: linear-gradient(45deg, #2E5266, #9FB1BC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.8;
  }
  
  .message {
    font-size: 2rem;
    margin: 1rem 0 2rem;
    color: #6E8898;
  }
  
  .home-link {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background: rgba(46, 82, 102, 0.3);
    color: #9FB1BC;
    text-decoration: none;
    border: 1px solid #6E8898;
    border-radius: 4px;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
  }
  
  .home-link:hover {
    background: rgba(46, 82, 102, 0.5);
    color: #ffffff;
    transform: translateY(-2px);
  }