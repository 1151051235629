.App {
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow: hidden;
  clip-path: inset(0);
}

.background-effect {
  position: fixed !important;
  min-width: 100%;
  min-height: 100%;
  pointer-events: none;
  overflow: hidden;
}

/* Ensure proper sizing on mobile */
html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.brand-text {
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
  cursor: default;
}

.brand-text h1 {
  font-family: 'Electrolize', sans-serif;
  font-weight: 400;
  font-size: 3.5rem;
  color: rgba(255, 255, 255, 0.95);
  letter-spacing: 0.25em;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  text-shadow: 0 0 25px rgba(159, 177, 188, 0.2);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.locations {
  font-family: 'Electrolize', sans-serif;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.15em;
  margin-top: 1.5rem;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
}

/* Split text effect on hover */
.brand-text:hover h1 {
  letter-spacing: 2em;
  opacity: 0;
  filter: blur(10px);
  transform: translateY(-100px);
}

.brand-text:hover .locations {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(50px);
}

/* Return animation */
.brand-text h1 {
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .brand-text h1 {
    font-size: 2.5rem;
    letter-spacing: 0.2em;
  }
  .brand-text:hover h1 {
    letter-spacing: 1em;
  }
  .locations {
    font-size: 0.9rem;
    margin-top: 1.2rem;
  }
}

@media (max-width: 480px) {
  .brand-text h1 {
    font-size: 2rem;
    letter-spacing: 0.15em;
  }
  .brand-text:hover h1 {
    letter-spacing: 0.8em;
  }
  .locations {
    font-size: 0.8rem;
    margin-top: 1rem;
  }
}

.login-link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  font-family: 'Electrolize', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.15em;
  padding: 12px 24px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  pointer-events: none;
  background: rgba(46, 82, 102, 0.1);
  backdrop-filter: blur(5px);
}

.brand-text:hover .login-link {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  pointer-events: auto;
}

.login-link:hover {
  background: rgba(46, 82, 102, 0.2);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translate(-50%, -50%) scale(1.05);
}

/* Update existing hover effects */
.brand-text {
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
  cursor: default;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-link {
    font-size: 1.1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .login-link {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

/* Add a subtle shadow to the edges */
.App-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 0;
}