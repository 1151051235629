.privacy-policy {
    min-height: 100vh;
    background-color: #000000;
    color: #ffffff;
    position: relative;
    overflow: hidden;
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 4rem 2rem;
    background: rgba(46, 82, 102, 0.1);
    backdrop-filter: blur(10px);
    position: relative;
    z-index: 1;
  }
  
  .privacy-policy h1 {
    color: #9FB1BC;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    text-align: center;
  }
  
  .privacy-policy h2 {
    color: #6E8898;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .privacy-policy section {
    margin-bottom: 2.5rem;
  }
  
  .privacy-policy p {
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .privacy-policy strong {
    color: #9FB1BC;
  }
  
  .privacy-policy em {
    color: #D3D0CB;
    font-style: italic;
  }
  
  /* Add the background animation from home page */
  .privacy-policy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 50%, rgba(46, 82, 102, 0.2) 0%, rgba(0, 0, 0, 0) 70%);
    z-index: 0;
  }